<template>
  <div class="j_dashboard_page_wrapper__">

    <v-btn @click="exportPDF">PDF</v-btn>

    <!-- <j-dashboard-layout-updater
      ref="layoutUpdater"
      :charts="charts"
      :chart-update="chartItemUpdated"
      :containers="containerAttrs"
      :layout="layout"
      @request-modal="onModalRequested"
    /> -->

    <component 
      v-model="formOpened"
      :is="formModalComponent"
      :key-value="keyValue"
      @updated="onUpdated"
    />
    
    <j-overlay :value="loading">
      <!-- <v-row justify="center" align="center"> -->
        <!-- <v-container>
          <v-progress-linear
            :active="active"
            :background-opacity="opacity"
            :bottom="bottom"
            :buffer-value="buffer"
            :height="height"
            :indeterminate="indeterminate"
            :query="query"
            :rounded="rounded"
            :stream="stream"
            :striped="striped"
            :top="top"
            :value="value"
            color="light-blue"
          ></v-progress-linear>
        </v-container> -->

        <j-spinner-orbit
          :border="3"
          :color="'#b3e5fc'"
          :animation-duration="1200"
          :size="320"
        />
      <!-- </v-row> -->
    </j-overlay>

  </div>
</template>

<script>

import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapActions } from "vuex"
import Loading from '@/mixins/loading.mixin'
import PDF from '@/mixins/pdf.mixin'
import JSysenvChartModals from '@/components/JSysenvChartModals'

// const Puppeteer = require('puppeteer')

export default {
  name: "service-dashboard",
  mixins: [
    Loading,
    PDF
  ],
  components: {
    ...JSysenvChartModals
  },
  data: () => ({
    charts: [],
    chartItemUpdated: null,
    containerAttrs: [],
    layout: {},

    formOpened: false,
    formModalComponent: null,
    keyValue: null,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
    ...mapState(__C.STORE_NAMESPACE.APP_SERVICE, ['codePropagated']),
    ...mapState(__C.STORE_NAMESPACE.INTENDED_DATAGRID, ['filteredValues']),

  },
  watch: {
    codePropagated: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) == JSON.stringify(oldVal)) return
        this.init()
      },
      deep: true
    },
  },
  beforeCreate() {
    this.loading = true
  },
  mounted() {
    this.setChild(__C.STORE_NAMESPACE_KEY.DASHBOARD)
    this.init()
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'setChild',
    ]),
    ...mapActions(__C.STORE_NAMESPACE.DASHBOARD, [
      'getDashboard', 
      'initService'
      ]),

    // genLayoutUpdater() {
    //   let props_ = {
    //     charts: this.charts,
    //     containers: this.containerAttrs,
    //     layout: this.layout,
    //   }
    //   let component = Vue.extend(JDashboardLayoutUpdater)
    //   let instance = new component({ propsData: props_ })
    //   instance.$on('request-modal', this.onModalRequested)
    //   instance.$mount()
    //   this.$refs.layoutUpdaterWrapper.appendChild(instance.$el)
    // },

    init() {
      this.initService().then(res => {
        this.setDashboard('init')
      })
    },
    setDashboard(reqType) {
      this.getDashboard(reqType).then(res => {
        if(!res) return

        this.charts = res.charts
        this.containerAttrs = res.dashboard.containerAttrs
        this.layout = res.dashboard.layoutAttrs
        this.loading = false
      })
    },
    onModalRequested(chartInfo) {
      this.formModalComponent = __C.CHART.CAT_CODE_COMPONENT[chartInfo.catCode]

      // Need little delay time to be done creating the component
      // before running.
      setTimeout(() => {
        this.keyValue = chartInfo.chartNo
        this.formOpened = true
      })
    },
    onUpdated(item) {
      this.chartItemUpdated = item
      // this.setDashboard()
    },
  }
}
</script>
